import React, { createContext, useContext, useMemo } from 'react';
import { UPDATE_LEGAL_INFORMATIONS } from 'src/graphql/user/mutations';
import { GET_MY_PRE_ELIGIBILITY, GET_USER_BY_ID } from 'src/graphql/user/queries';
import { useSafeLazyQuery, useSafeMutation } from 'src/services/apollo-client/wrappers';

const UserContext = createContext();

const useUser = () => useContext(UserContext);

function UserProvider({ children }) {
  const [getMyPreEligibility, getMyPreEligibilityState] = useSafeLazyQuery(GET_MY_PRE_ELIGIBILITY);
  const [getUserById, { data: getUserByIdData, ...getUserByIdState }] =
    useSafeLazyQuery(GET_USER_BY_ID);

  const [
    updateLegalInformations,
    { data: updateLegalInformationsData, ...updateLegalInformationsState },
  ] = useSafeMutation(UPDATE_LEGAL_INFORMATIONS, {
    refetchQueries: ['getSurveillances'],
  });

  const memoizedContextValue = useMemo(
    () => ({
      getMyPreEligibility,
      getMyPreEligibilityState,
      getUserById,
      getUserByIdState,
      updateLegalInformations,
      updateLegalInformationsData,
      updateLegalInformationsState,
      user: getUserByIdData?.getUserById,
    }),
    [
      getMyPreEligibility,
      getMyPreEligibilityState,
      getUserById,
      getUserByIdData?.getUserById,
      getUserByIdState,
      updateLegalInformations,
      updateLegalInformationsData,
      updateLegalInformationsState,
    ]
  );

  return <UserContext.Provider value={memoizedContextValue}>{children}</UserContext.Provider>;
}

export { UserProvider, useUser };
