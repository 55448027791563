import { gql } from '@apollo/client';

const rewardFragment = gql`
  fragment Reward on Reward {
    id
    type
    amount
    status
    userId
    tripId
    createdAt
    updatedAt
  }
`;

export default rewardFragment;
