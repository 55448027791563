import { Box } from '@mui/material';
import Lottie from 'lottie-react';
import React from 'react';
import backgroundForm from 'src/assets/images/background-form.png';

function AnimationWrapper({ animationData, animationProps, children, withBackground }) {
  return (
    <Box height="auto" position="relative" width="100%">
      {withBackground && (
        <Box
          component="img"
          height="100%"
          src={backgroundForm}
          sx={{
            left: '50%',
            p: 3,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          width="auto"
        />
      )}
      {!children && <Lottie animationData={animationData} {...animationProps} />}
      {children && children}
    </Box>
  );
}

export default AnimationWrapper;
