import React, { useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import USER_ROLES, { isGranted } from 'src/constants/userRoles';
import useAuth from 'src/contexts/auth/useAuth';

import AdminRoutes from './admin';
import AppPageRoutes from './app';
import LandingPageRoutes from './landing';
import { ADMIN_ROOT_PATH, APP_ROOT_PATH } from './paths';
import UserRoutes from './users';

const getUserRoutes = ({ isAuthenticated, user }) => {
  if (!isAuthenticated || isGranted(user?.role, [USER_ROLES.BASIC]))
    return [
      {
        children: UserRoutes,
        path: 'app',
      },
    ];

  return [];
};

const getAdminRoutes = ({ isAuthenticated, user }) => {
  if (!isAuthenticated || isGranted(user?.role, [USER_ROLES.SUPER_ADMIN]))
    return [
      {
        children: AdminRoutes,
        path: 'admin',
      },
      {
        element: <Navigate replace to={ADMIN_ROOT_PATH} />,
        path: 'app/*',
      },
    ];

  return [];
};

export default function GlobalRoutes() {
  const { isAuthenticated, user } = useAuth();

  const Routes = useMemo(() => {
    const routes = [];

    routes.push(
      ...getUserRoutes({ isAuthenticated, user }),
      ...getAdminRoutes({ isAuthenticated, user })
    );

    // Common Routes
    routes.push({
      children: AppPageRoutes,
      path: 'open',
    });

    if (!window.ReactNativeWebView) {
      routes.push({
        children: LandingPageRoutes,
        path: '',
      });
    } else {
      routes.push({
        element: <Navigate replace to={APP_ROOT_PATH} />,
        path: '',
      });
    }

    return routes;
  }, [isAuthenticated, user]);

  const routes = useRoutes(Routes);

  return routes;
}
