import { gql } from '@apollo/client';

import { userFragment } from '../fragments';

const DELETE_ME = gql`
  mutation deleteMe {
    deleteMe
  }
`;

const CONTACT_US = gql`
  mutation contactUs($input: ContactUsInput!) {
    contactUs(input: $input)
  }
`;

const ATTACH_PUSH_TOKEN = gql`
  mutation attachPushToken($pushToken: String) {
    attachPushToken(pushToken: $pushToken)
  }
`;

const SAVE_LAST_POSITION = gql`
  mutation saveLastPosition($input: AddressInput!) {
    saveLastPosition(input: $input)
  }
`;

const UPDATE_LEGAL_INFORMATIONS = gql`
  mutation updateLegalInformations($userId: ID!, $input: UpdateLegalInformationsInput!) {
    updateLegalInformations(userId: $userId, input: $input) {
      ...User
    }
  }
  ${userFragment}
`;

export { DELETE_ME, CONTACT_US, ATTACH_PUSH_TOKEN, SAVE_LAST_POSITION, UPDATE_LEGAL_INFORMATIONS };
