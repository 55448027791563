import { gql } from '@apollo/client';

const surveillanceFragment = gql`
  fragment Surveillance on Surveillance {
    id
    userId
    reason
    createdAt
    isSolved
    isRefused
    solvedAt
    drivingLicence
    priority
    expirationDate
    blockedJourneyId
  }
`;

export default surveillanceFragment;
