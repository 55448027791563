import { gql } from '@apollo/client';

const userFragment = gql`
  fragment User on User {
    id
    firstname
    lastname
    email
    isUserPhoneVerified
    isUserEmailVerified
    birthdate
    phone
    role
    civility
    sponsorCode
    isOtpVerificationRequired
    legalFirstname
    legalLastname
    lastLatitude
    lastLongitude
    lastIp
    lastIpChangedAt
    lastPositionUpdatedAt
    lastOtpCodeGeneratedAt
    lastEmailVerificationCodeGeneratedAt
    createdAt
    updatedAt
    avatar
    selfie
    kmDriven
    co2Saved
    availableBalance
    realBalance
    hasUploadedSelfie
    nationality
    pushToken
    hasBeenActiveAtLeastOnce
    hasUploadedDrivingLicense
    averageRatings {
      averageRatingAsPassenger
      averageRatingAsConductor
      globalAverageRating
    }
    ratings {
      ratingsAsPassengerCount
      ratingsAsConductorCount
    }
    sponsorRewardsInformations {
      sponsoredUsers {
        id
        firstname
        lastname
        avatar
        createdAt
      }
      referralRewards {
        type
        amount
        status
        userId
      }
      remainingReferralRewards
      maxReferralRewards
      wonRewardsAmount
    }
    isIneligibleForShortDistanceRewardsDueToConcurrentParticipation
    isIneligibleForLongDistanceRewardsDueToConcurrentParticipation
    tripsDoneAsConductorCount
    tripsDoneAsPassengerCount
  }
`;

export default userFragment;
