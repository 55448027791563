import { trimEnd } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import USER_ROLES, { isGranted } from 'src/constants/userRoles';
import useAuth from 'src/contexts/auth/useAuth';
import useConfig from 'src/hooks/useConfig';
import { ADMIN_ROOT_PATH } from 'src/routes/paths';

const PRESETS_COLOR = {
  BASIC: 'theme6',
  SUPER_ADMIN: 'theme1',
};

function ThemeHandler({ children }) {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();
  const [hasTheme, setHasTheme] = useState(false);
  const { onChangePresetColor, presetColor } = useConfig();

  const match = useMemo(
    () => location.pathname.indexOf(trimEnd(ADMIN_ROOT_PATH, '/')) === 0,
    [location]
  );

  const isAdmin = useMemo(() => isGranted(user?.role, [USER_ROLES.SUPER_ADMIN]), [user?.role]);

  useEffect(() => {
    const activePreset =
      (match && !isAuthenticated) || (match && isAdmin)
        ? PRESETS_COLOR.SUPER_ADMIN
        : PRESETS_COLOR.BASIC;

    if (presetColor !== activePreset) {
      onChangePresetColor(activePreset);
      setHasTheme(true);
    } else {
      setHasTheme(true);
    }
  }, [isAdmin, isAuthenticated, location, match, onChangePresetColor, presetColor, user]);

  if (!hasTheme) return null;

  return children;
}

export default ThemeHandler;
